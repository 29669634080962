/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import * as iots from 'io-ts';
import snakeCase from 'lodash/snakeCase';
import { get } from 'api/client';
import Table from 'components/table/Table';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import BankruptCompaniesOwnedPlotsButton from './BankruptCompaniesOwnedPlotsButton';
import { COMPANY_NAME_COLUMN, DEPARTMENT_COLUMN, getColumnHeader, IOCompanyOwned, JUDGMENT_DATE_COLUMN, OFFICIAL_JOURNAL_COLUMN, SIREN_COLUMN, TOWN_COLUMN, } from './CompanyOwnedPlotsTable';
export const IOBankruptCompanyTableData = iots.intersection([
    IOCompanyOwned,
    iots.type({
        judgmentType: iots.string,
        judgmentDate: iots.union([iots.string, iots.null]),
        countOwnedPlots: iots.number,
    }),
]);
const initialSort = [
    {
        id: 'countOwnedPlots',
        desc: true,
    },
];
const columns = [
    {
        id: 'columns',
        columns: [
            // @ts-ignore
            JUDGMENT_DATE_COLUMN,
            // @ts-ignore
            DEPARTMENT_COLUMN,
            // @ts-ignore
            TOWN_COLUMN,
            // @ts-ignore
            SIREN_COLUMN,
            {
                accessorKey: 'judgmentType',
                header: () => getColumnHeader('judgment_type'),
                accessorFn: (row) => {
                    if (!row.judgmentType)
                        return t('table.empty_column');
                    return row.judgmentType;
                },
                cell: ({ row }) => row.original.judgmentType,
                enableGlobalFilter: false,
                filterFn: 'arrIncludesSome',
            },
            OFFICIAL_JOURNAL_COLUMN,
            {
                accessorKey: 'countOwnedPlots',
                header: () => getColumnHeader('owned_plots'),
                cell: (info) => {
                    return (<BankruptCompaniesOwnedPlotsButton data={{
                            siren: info.row.original.siren,
                            countOwnedPlots: info.row.original.countOwnedPlots,
                        }}/>);
                },
                enableGlobalFilter: false,
                filterFn: 'inNumberRange',
            },
            // @ts-ignore
            COMPANY_NAME_COLUMN,
        ],
    },
];
const EXPORTED_FIELDS = [
    'judgmentDate',
    'department',
    'town',
    'siren',
    'judgmentType',
    'officialNoticeNumber',
    'countOwnedPlots',
    'companyName',
];
const formatExportHeader = (t) => (header) => t(`tabs.extra_tabs.company_owned_plots.columns.${snakeCase(header)}`);
const formatExportValue = (value, field, data) => {
    if (field === 'officialNoticeNumber') {
        if (!data.fileNumber || !data.officialNoticeNumber) {
            return '';
        }
        return `https://www.bodacc.fr/annonce/detail-annonce/${data.fileNumber.substring(0, 1)}/${data.fileNumber.substring(1)}/${data.officialNoticeNumber}`;
    }
    return value;
};
const fetchBankruptCompanies = (department) => () => get(iots.array(IOBankruptCompanyTableData), `legacy/${department}/bankrupt-companies`);
const BankruptCompaniesPanel = () => {
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const department = selectedTown?.department;
    const { data, isInitialLoading: isLoading } = useQuery({
        queryKey: ['bankrupt-companies', department],
        queryFn: fetchBankruptCompanies(department),
        enabled: !!department,
    });
    return (<Stack paddingX={4} height="100%">
      <Table variant="simple" id="bankrupt-companies" columns={columns} data={data || []} initialSort={initialSort} isLoading={isLoading} exportTitle={t('tabs.extra_tabs.bankrupt_companies.title')} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue}/>
    </Stack>);
};
export default memo(BankruptCompaniesPanel);
