import { useEffect, useState } from 'react';
import * as React from 'react';
import { ColorPicker, ColorService, useColor, } from 'react-color-palette';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Heading, HStack, Input, Spinner, Stack, Text, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_PERSONALIZATION } from 'analytics/useAnalytics';
import PersonalisationConfirmModal from 'components/tabs/personalization/PersonalisationConfirmModal';
import usePersonalizationPlot from 'hooks/personalization/usePersonalizationPlot';
import ArrowLeftIcon from 'images/icons/ArrowLeft';
import { PLOT_STATUS_OPTIONS } from 'utils/constants';
import { formatDate } from 'utils/formatDate';
import 'react-color-palette/css';
const PersonalizationPlotStatus = () => {
    const { trackEvent } = useAnalytics();
    const { t } = useTranslation();
    const methods = useForm();
    const [selectedColor, setSelectedColor] = useColor('#561ecb');
    const [currentPersonalization, setCurrentPersonalization] = useState(null);
    const { personalizationPlotStatus, isLoading, updatePersonalizationPlotStatus, isUpdateStatus, } = usePersonalizationPlot();
    const [personalization, setPersonalization] = useState([]);
    const [personalizationState, setPersonalizationState] = useState(null);
    useEffect(() => {
        if (!personalizationPlotStatus) {
            return;
        }
        const perso = [];
        let persoState = null;
        if (personalizationPlotStatus.status.length > 0) {
            personalizationPlotStatus.status.map((item) => {
                perso.push({
                    id: item.statusId,
                    statusId: item.statusId,
                    label: item.label,
                    color: item.color,
                    order: item.order,
                });
            });
            persoState = {
                modificationUser: personalizationPlotStatus.modificationUser,
                modificationDate: formatDate(personalizationPlotStatus.modificationDate, 'extended'),
            };
        }
        else {
            Object.values(PLOT_STATUS_OPTIONS).map((item) => {
                perso.push({
                    id: item.value,
                    statusId: item.value,
                    label: item.label,
                    color: item.color,
                    order: item.legendOrder,
                });
            });
        }
        setPersonalization(perso);
        setPersonalizationState(persoState);
    }, [personalizationPlotStatus]);
    const chooseColor = (index, color) => {
        if (currentPersonalization === index) {
            setCurrentPersonalization(null);
        }
        else {
            setCurrentPersonalization(index);
        }
        const rgb = ColorService.hex2rgb(color);
        setSelectedColor({
            hex: color,
            rgb: ColorService.hex2rgb(color),
            hsv: ColorService.rgb2hsv(rgb),
        });
    };
    const setColor = (color) => {
        const perso = [...personalization];
        perso[currentPersonalization].color = color.hex;
        setSelectedColor(color);
        setPersonalization(perso);
    };
    const setLabel = (event, index) => {
        const perso = [...personalization];
        perso[index].label = event.target.value;
        setPersonalization(perso);
    };
    const resetStatus = () => {
        const perso = [];
        Object.values(PLOT_STATUS_OPTIONS).map((item) => {
            perso.push({
                id: item.value,
                statusId: item.value,
                label: item.label,
                color: item.color,
                order: item.legendOrder,
            });
        });
        setPersonalization(perso);
    };
    const onSubmit = () => {
        trackEvent({
            category: ANALYTICS_PERSONALIZATION,
            action: 'Mise à jour des statuts des parcelles',
        });
        setCurrentPersonalization(null);
        updatePersonalizationPlotStatus(personalization);
    };
    return (<Stack spacing={1} padding={4} alignItems="start">
      <Heading as="h4" variant="h4">
        {personalizationPlotStatus.countCustomerAccount > 1
            ? t('tabs.personalization.section.plot_status.title')
            : t('tabs.personalization.section.plot_status.title_alone')}
      </Heading>
      <FormProvider {...methods}>
        <Box marginBottom={2}>
          {isLoading ? (<Spinner />) : (personalization.map((perso, index) => {
            return (<HStack spacing={2} key={index} width="300px">
                  <Input name="name[]" size="sm" onChange={(input) => setLabel(input, index)} value={perso.label} maxLength={64}/>
                  <Box cursor="pointer" position="relative">
                    <Box backgroundColor={perso.color} minWidth="25px" minHeight="25px" maxWidth="25px" maxHeight="25px" onClick={() => chooseColor(index, perso.color)} border={currentPersonalization === index
                    ? '2px solid black'
                    : 'none'}/>
                    <Box display={currentPersonalization === index ? 'block' : 'none'} width="350px" position="absolute" top="-2px" left="45px" boxShadow="md" borderRadius="md" backgroundColor="#FFFFFF" padding={2}>
                      <Box position="relative">
                        <Box position="absolute" right="-20px" top="-18px" backgroundColor="#FFFFFF" padding="6px" boxShadow="3px 1px 5px rgba(0,0,0,0.1)" borderRadius="50%" lineHeight="0.55">
                          <CloseIcon fontSize="12px" onClick={() => setCurrentPersonalization(null)} _hover={{ color: 'colorMode.font900' }}/>
                        </Box>
                        <Box position="absolute" left="-29px" top="-6px">
                          <ArrowLeftIcon />
                        </Box>
                        <ColorPicker height={150} hideInput={['hsv']} hideAlpha={true} color={selectedColor} onChange={setSelectedColor} onChangeComplete={setColor}/>
                      </Box>
                    </Box>
                  </Box>
                </HStack>);
        }))}
        </Box>
      </FormProvider>
      <HStack>
        <PersonalisationConfirmModal onSubmit={onSubmit} isUpdated={isUpdateStatus} content={t('tabs.personalization.section.plot_status.modal_content')}/>
        <Button isDisabled={isUpdateStatus} variant="primary" onClick={resetStatus}>
          {t('tabs.personalization.section.plot_status.reset')}
        </Button>
      </HStack>
      {personalizationState && (<Text marginTop={1} fontSize="12px">
          {t('tabs.personalization.section.plot_status.state', personalizationState)}{' '}
          {personalizationState.modificationDate}
        </Text>)}
    </Stack>);
};
export default PersonalizationPlotStatus;
