import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Image } from '@chakra-ui/react';
import useAnalytics from 'analytics/useAnalytics';
import { ROADMAP } from 'context/MapContext';
import { useResetFilters } from 'hooks/contexts/useFiltersContext';
import { useResetLayersTree } from 'hooks/contexts/useLayersTreeContext';
import { useResetLegends } from 'hooks/contexts/useLegendContext';
import { useResetLocation } from 'hooks/contexts/useLocationContext';
import { useChangeMapDisplay, useResetMap } from 'hooks/contexts/useMapContext';
import { useResetMenu } from 'hooks/contexts/useMenuContext';
import { useResetFeasibility } from 'hooks/contexts/useSecondaryPlotsContext';
import { useResetTabs } from 'hooks/contexts/useTabsContext';
import useSiteLogo from 'hooks/useSiteLogo';
import { filtersKey } from 'utils/filters';
const HeaderLogo = () => {
    const logo = useSiteLogo();
    const { trackEvent } = useAnalytics();
    const resetTabs = useResetTabs();
    const resetFilters = useResetFilters();
    const resetLayersTree = useResetLayersTree();
    const resetMap = useResetMap();
    const resetLegends = useResetLegends();
    const resetMenu = useResetMenu();
    const resetLocation = useResetLocation();
    const resetFeasibility = useResetFeasibility();
    const navigate = useNavigate();
    const changeMapDisplay = useChangeMapDisplay();
    return (<Box paddingLeft={4} onClick={() => {
            resetTabs({});
            resetFilters([filtersKey.TOWNS]);
            resetLayersTree();
            resetMap();
            resetLegends();
            resetMenu();
            resetLocation({ resetTown: true });
            resetFeasibility();
            changeMapDisplay(ROADMAP);
            navigate('/');
            trackEvent({
                category: 'Header',
                action: 'Logo',
            });
        }} cursor="pointer" data-cy="header_logo">
      <Image alt="Logo KelFoncier" src={logo} objectFit="contain" maxWidth="130px" maxHeight="45px" minHeight="45px"/>
    </Box>);
};
export default HeaderLogo;
