import * as React from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, Icon, Link, Spinner, Stack, Text, useDisclosure, VStack, } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import useAnalytics, { ANALYTICS_CATEGORY_DOWNLOAD, ANALYTICS_COURIER, ANALYTICS_MASQUER, ANALYTICS_PLOT_TAB, ANALYTICS_RACCOURCI, } from 'analytics/useAnalytics';
import { getFile } from 'api/client';
import CreateProjectButton from 'components/buttons/CreateProjectButton';
import FilterCategoryDocument from 'components/FilterCategoryDocument';
import SharePlotModal from 'components/modals/SharePlotModal';
import CourierCreateButton from 'components/tabs/couriers/CourierCreateButton';
import CourierDrawer from 'components/tabs/couriers/courierForm/CourierDrawer';
import { plotFeasibilityFields } from 'components/tabs/plots/PlotSections/feasibility/calculator';
import { getFeasibilityWithCadastre } from 'components/tabs/plots/PlotSections/feasibility/PlotFeasibility';
import { ROLES } from 'context/AuthContext';
import { useUserData } from 'hooks/contexts/useAuthContext';
import { useLayout } from 'hooks/contexts/useLayoutContext';
import { useTowns } from 'hooks/contexts/useLocationContext';
import { useGetFeasibilityPlots, useIsLoadingFeasibilityPlot, } from 'hooks/contexts/useSecondaryPlotsContext';
import { useSetSectionHashToUrl } from 'hooks/contexts/useTabsContext';
import usePlotFarming from 'hooks/plot/usePlotFarming';
import usePlotFeasibility from 'hooks/plot/usePlotFeasibility';
import usePlotOccupants from 'hooks/plot/usePlotOccupants';
import usePlotOwnersInfo from 'hooks/plot/usePlotOwnersInfo';
import usePlotSourcePost from 'hooks/plot/usePlotSourcePost';
import useCurrentSite from 'hooks/useCurrentSite';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import EarthIcon from 'images/icons/Earth';
import ZoomMoinsIcon from 'images/icons/ZoomMoins';
import ZoomPlusIcon from 'images/icons/ZoomPlus';
import { FEASIBILITY_LIGHT_PLOT_TAB, FEASIBILITY_PLOT_TAB, OCCUPANT_PLOT_TAB, OWNER_PLOT_TAB, PLOT_INFO_FARMING, PLU_REGULATION_PLOT_TAB, SOURCE_POST_PLOT_TAB, } from 'types/api';
import { FROM_PLOT, SPACING_PANEL } from 'utils/constants';
import { formatNumberToLocale } from 'utils/formatNumber';
import { getPlotCadastreId, getRiskLink } from 'utils/plots';
import { removeAccent } from 'utils/search';
import PlotInfoMultiText from './PlotInfoMultiText';
import PlotInfoText from './PlotInfoText';
import PlotStatus from './PlotStatus';
const PlotInfoDetail = ({ plot, isLoading, }) => {
    const { t, i18n } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { currentSite } = useCurrentSite();
    const { hasPlotInfo, hasPlotTab, hasDashboardItem } = useSiteConfiguration();
    const { countOccupant } = usePlotOccupants(plot);
    const { sourcePosts } = usePlotSourcePost(plot?.id, hasPlotTab('source_post'));
    const layout = useLayout();
    const towns = useTowns();
    const userData = useUserData();
    const setSectionHashToUrl = useSetSectionHashToUrl();
    const [courierInfo, setCourierInfo] = useState();
    const { getDisclosureProps, getButtonProps } = useDisclosure();
    const isLoadingFeasibilityPlots = useIsLoadingFeasibilityPlot();
    const getFeasibilityPlots = useGetFeasibilityPlots();
    const feasibilityPlots = getFeasibilityPlots(plot.id);
    const { plotFeasibility } = usePlotFeasibility(plot?.townId, plot?.id, false);
    const { plotFarming, isInitialLoading: isLoadingFarming } = usePlotFarming(hasPlotInfo(PLOT_INFO_FARMING), plot);
    const feasibility = useMemo(() => plotFeasibility
        ? getFeasibilityWithCadastre(plotFeasibility, plot)
        : null, [plotFeasibility]);
    const buttonListRef = React.useRef(null);
    const dimensions = useSize(buttonListRef);
    const rowConfig = plotFeasibilityFields['floorSpace'];
    const allData = feasibility ? [...Object.values(feasibilityPlots)] : [];
    const totalFloorAreaValue = feasibility && !isLoadingFeasibilityPlots
        ? rowConfig?.getTotalValue &&
            rowConfig.getTotalValue(allData, 'floorSpace')
        : plot?.floorArea;
    const buttonProps = getButtonProps();
    const disclosureProps = getDisclosureProps();
    const { countOwner } = usePlotOwnersInfo(currentSite?.ownerSubscription, plot);
    if (!hasPlotInfo('status') && !hasPlotInfo('information')) {
        return null;
    }
    const pluTabEnabled = hasPlotTab('plu_regulation');
    const cadastreLinkFormatTown = (plot) => {
        const townName = plot.town ?? towns[plot?.townId]?.name;
        return townName?.replaceAll('-', ' ');
    };
    const getCadastreLink = () => `https://www.cadastre.gouv.fr/scpc/rechercherPlan.do?numeroVoie=${plot.streetNumber}&indiceRepetition=${plot.streetRep}&nomVoie=${removeAccent(plot.street.replace("'", ' '))}&codePostal=${towns[plot?.townId]?.zipCode}&ville=${cadastreLinkFormatTown(plot)}&nbResultatParPage=10`;
    return (<VStack maxW={layout === 'vertical' ? 'auto' : '450px'} width="100%" alignItems="stretch" spacing={SPACING_PANEL}>
      {hasPlotInfo('status') && (<Box backgroundColor="colorMode.base" padding={4} data-cy="plot_status_info">
          <PlotStatus plotId={plot?.id}/>
        </Box>)}
      {hasPlotInfo('information') && (<Box backgroundColor="colorMode.base" padding={4} data-cy="plot_info_detail">
          <VStack alignItems="flex-start">
            <Text textStyle="paragraph">
              <Text as="span" textStyle="paragraph" fontWeight={600}>
                {t('tabs.plots.section.plot.town')}
                {' : '}
              </Text>
              {plot?.town}
            </Text>

            {/* cadastre */}
            <PlotInfoText label={t('tabs.plots.section.plot.plot_id')} value={getPlotCadastreId(plot)}/>

            {/* plot surface */}
            <PlotInfoText label={t('tabs.plots.section.plot.surface')} value={`${plot?.surface ?? ''} m²`}/>

            {/* zones and subzones */}
            {hasPlotInfo('zone') && (<Text textStyle="paragraph">
                <Text as="span" fontWeight={600}>
                  {t('tabs.plots.section.plot.zone')}
                  {' : '}
                </Text>
                <Text as="span" whiteSpace="nowrap" color={pluTabEnabled ? 'red.500' : undefined} textDecoration={pluTabEnabled ? 'underline' : undefined} cursor={pluTabEnabled ? 'pointer' : undefined} onClick={pluTabEnabled
                    ? () => {
                        setSectionHashToUrl(PLU_REGULATION_PLOT_TAB);
                        trackEvent({
                            category: ANALYTICS_RACCOURCI,
                            action: 'Onglet PLU',
                        });
                    }
                    : null}>
                  {plot?.zone
                    .slice(1, plot.zone.length - 1)
                    .replaceAll(';', ' ')}
                  {' / '}
                  {plot?.subZone
                    .slice(1, plot.subZone.length - 1)
                    .replaceAll(';', ' ')}
                </Text>
              </Text>)}

            {/* pinel */}
            {towns[plot?.townId]?.pinel && (<PlotInfoText label={t('tabs.plots.section.plot.pinel')} value={towns[plot?.townId]?.pinel}/>)}

            {hasPlotInfo(PLOT_INFO_FARMING) && (<PlotInfoMultiText values={plotFarming?.length > 0
                    ? plotFarming?.map((value) => {
                        return value.name + ' (' + value.info + ')';
                    })
                    : [t('global.none_2').toLowerCase()]} label={t('tabs.plots.section.plot.farming')} isLoading={isLoadingFarming}/>)}

            {plot?.streetLine > 0 && (<Text textStyle="paragraph">
                <Text as="span" textStyle="paragraph" fontWeight={600}>
                  {t('tabs.plots.section.plot.street_line')}
                  {' : '}
                </Text>

                {t('tabs.plots.section.plot.street_line_meters', {
                    streetLine: plot?.streetLine,
                })}
              </Text>)}

            {/* surface */}
            {hasPlotTab(FEASIBILITY_PLOT_TAB) &&
                !hasPlotTab(FEASIBILITY_LIGHT_PLOT_TAB) &&
                totalFloorAreaValue && (<HStack alignItems="center" spacing="3px">
                  <Text textStyle="paragraph">
                    {t('tabs.plots.section.plot.floor_surface')}
                    {!isLoadingFeasibilityPlots && (<Button height="auto" padding={0} size="sm" variant="link-primary" textStyle="paragraph" onClick={() => {
                        setSectionHashToUrl(FEASIBILITY_PLOT_TAB);
                        trackEvent({
                            category: ANALYTICS_RACCOURCI,
                            action: 'Onglet Faisabilité',
                        });
                    }} minW="auto">
                        {t('tabs.plots.section.plot.floor_surface_value', {
                        surface: totalFloorAreaValue,
                    })}
                      </Button>)}
                  </Text>
                  {isLoadingFeasibilityPlots && <Spinner size="sm"/>}
                </HStack>)}

            {/* owners */}
            {hasPlotTab('owner') && countOwner > 0 && !isLoading && (<HStack spacing="3px" alignItems="center">
                <Text textStyle="paragraph">
                  {t('tabs.plots.section.plot.owners')}
                </Text>
                <Button height="auto" minW="auto" padding={0} size="sm" variant="link-primary" textStyle="paragraph" onClick={() => {
                    setSectionHashToUrl(OWNER_PLOT_TAB);
                    trackEvent({
                        category: ANALYTICS_RACCOURCI,
                        action: 'Onglet Propriétaires',
                    });
                }}>
                  {countOwner}
                </Button>
              </HStack>)}

            {/* occupants */}
            {hasPlotTab('occupant') && countOccupant > 0 && !isLoading && (<HStack spacing="3px" alignItems="center">
                <Text textStyle="paragraph">
                  {t('tabs.plots.section.plot.occupants')}
                </Text>
                <Button height="auto" minW="auto" padding={0} size="sm" variant="link-primary" textStyle="paragraph" onClick={() => {
                    setSectionHashToUrl(OCCUPANT_PLOT_TAB);
                    trackEvent({
                        category: ANALYTICS_RACCOURCI,
                        action: 'Onglet Occupants',
                    });
                }}>
                  {countOccupant}
                </Button>
              </HStack>)}

            {/* source post */}
            {hasPlotTab('source_post') &&
                sourcePosts?.length > 0 &&
                !isLoading && (<HStack spacing="3px" alignItems="center">
                  <Text textStyle="paragraph">
                    {t('tabs.plots.section.plot.source_post')} :
                  </Text>
                  <Button height="auto" minW="auto" padding={0} size="sm" variant="link-primary" textStyle="paragraph" onClick={() => {
                    setSectionHashToUrl(SOURCE_POST_PLOT_TAB);
                    trackEvent({
                        category: ANALYTICS_RACCOURCI,
                        action: 'Onglet poste source',
                    });
                }}>
                    {formatNumberToLocale(sourcePosts[0].distance, i18n.language)}{' '}
                    km ({sourcePosts[0].name})
                  </Button>
                </HStack>)}

            {/* address */}
            {plot?.address && plot.address.length > 0 && (<>
                <Text textStyle="paragraph">
                  <Text as="span" textStyle="paragraph" fontWeight={600}>
                    {t('tabs.plots.section.plot.address')}
                    {' : '}
                  </Text>
                  <br />
                  {plot.address[0]}
                </Text>

                {plot.address.length > 1 && (<>
                    <Button variant="link" {...buttonProps} leftIcon={<Box color="blue.500" backgroundColor="blue.100" borderRadius="md" width="20px" display="flex" justifyContent="center">
                          {disclosureProps.hidden ? (<ZoomPlusIcon width="10" fill="currentColor"/>) : (<ZoomMoinsIcon width="10" fill="currentColor"/>)}
                        </Box>} onClick={() => {
                        trackEvent({
                            category: ANALYTICS_MASQUER,
                            action: 'Parcelle voir toutes les adresses',
                        });
                        buttonProps?.onClick();
                    }}>
                      <Text color="blue.500" as="span" textStyle="small">
                        {t('tabs.plots.section.plot.see_all_addresses')}
                      </Text>
                    </Button>
                    <Box {...disclosureProps}>
                      {plot.address.slice(1).map((address, index) => (<Text key={index} textStyle="small">
                          {address}
                        </Text>))}
                    </Box>
                  </>)}
              </>)}

            <Stack direction={dimensions?.width < 500 ? 'column' : 'row'} ref={buttonListRef} flexWrap="wrap" width="100%" spacing={2}>
              <CreateProjectButton plotIds={[plot?.id]} townId={plot?.townId} variant="primary" analyticsAction={ANALYTICS_PLOT_TAB} analyticsCategory="Créer un projet via le détail d'un parcelle"/>

              {userData?.roles?.includes(ROLES.ROLE_MANAGER) && (<SharePlotModal plot={plot}/>)}
              {hasDashboardItem('courier') && (<CourierCreateButton size="md" courierInfoDefault={{ plotId: plot.id }} setCourierInfo={setCourierInfo} analyticsCategory={ANALYTICS_COURIER} analyticsDetail="Créer un courier dans l'information d'une parcelle" fromPanel={FROM_PLOT}/>)}
            </Stack>

            <Box paddingBottom={2}>
              <FilterCategoryDocument textStyle="paragraph" documentKeys={[
                'carence',
                'charte',
                'notice',
                'oap',
                'ort',
                'pluFutur',
                'plu',
            ]} from={FROM_PLOT}/>
            </Box>
            <Link marginBottom={2} variant="secondary" href={getRiskLink(plot)} isExternal onClick={() => {
                trackEvent({
                    category: ANALYTICS_PLOT_TAB,
                    action: 'Ouvrir le rapport de risques dans la parcelle',
                });
            }}>
              <Icon as={ExternalLinkIcon} width="16px" height="16px" fill="currentColor" marginRight="8px"/>
              {t('tabs.plots.section.plot_rules.download_risk_report_title')}
            </Link>
            <Box paddingBottom={2}>
              <Link isExternal href={getCadastreLink()} variant="secondary" display="flex" alignItems="center" onClick={() => {
                trackEvent({
                    category: ANALYTICS_PLOT_TAB,
                    action: 'Accéder au cadastre',
                });
            }}>
                <Icon as={ExternalLinkIcon} width="16px" height="16px" fill="currentColor" marginRight="8px"/>
                {`${t(`tabs.plots.section.plot.cadastre_link`)}`}
              </Link>
              <Text marginTop={1} fontSize="12px">
                {`${t(`tabs.plots.section.plot.cadastre_info`)}`}
              </Text>
            </Box>
            {hasPlotInfo('google') && plot && (<Link onClick={() => {
                    trackEvent({
                        category: ANALYTICS_CATEGORY_DOWNLOAD,
                        action: 'Visualiser sur google earth',
                    });
                    getFile(`legacy/google-earth-file/${plot?.id}`, 'kmz');
                }} variant="secondary" display="flex" alignItems="center">
                <Icon as={EarthIcon} width="16px" height="16px" fill="currentColor" marginRight="8px"/>
                {t('tabs.plots.section.plot.see_google_earth')}
              </Link>)}
          </VStack>
        </Box>)}
      <CourierDrawer courierInfo={courierInfo} handleClose={() => setCourierInfo(undefined)}/>
    </VStack>);
};
export default PlotInfoDetail;
