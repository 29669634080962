import * as iots from 'io-ts';
import { ELEVATION_OPTIONS_KEY, getSelectedOptionsLabel, } from 'components/sidebar/PlotSearchSideBar/form/AdvancedSearch';
export const BUILDABLE_OPTIONS_KEY = {
    2: 'sidebar.search_plots.buildable_options.allowed',
    3: 'sidebar.search_plots.buildable_options.allowed_conditionally',
    1: 'sidebar.search_plots.buildable_options.forbidden',
};
export const PLOTS_SEARCH_DEFAUT_FORM_VALUES = {
    zones: [],
    zonesApplicable: [],
    plotAreaMin: '',
    plotAreaMax: '',
    buildable: [],
    allowedFloorAreaMin: '',
    allowedFloorAreaMax: '',
    permittedBuildingFootprintMin: '',
    permittedBuildingFootprintMax: '',
    authorizedLevelMin: '',
    undevelopedPlots: false,
    possibleElevations: [],
    smallFootprints: false,
    currentBuildingFootprintMin: '',
    currentBuildingFootprintMax: '',
    buildingPurpose: '',
    constructionYearMin: '',
    constructionYearMax: '',
    eaveHeightMin: '',
    eaveHeightMax: '',
    numberOfLevelsMin: '',
    numberOfLevelsMax: '',
    buildingFloorAreaMin: '',
    buildingFloorAreaMax: '',
    roofingMaterial: '',
    wallMaterial: '',
    coOwnerLots: '',
    excludeFloodProneAreas: false,
    officeConstruction: [],
    storeConstruction: [],
    warehouseConstruction: [],
    removeStreets: true,
    roadLinearMin: '',
    roadLinearMax: '',
    reducedVATZone: '',
    numberOfOwners: [],
    typeOfOwners: [],
    titleDeeds: '0',
    ownerMainActivity: '',
    ownerName: [],
    ownerSiren: '',
    prefix: '',
    section: '',
    number: '',
    socialMix: false,
    socialMixMin: '',
    socialMixMax: '',
    farming: [],
};
export const PLOTS_SEARCH_FIELDS_TRANSLATIONS = {
    zones: 'sidebar.search_plots.plu_areas',
    zonesApplicable: 'sidebar.search_plots.plu_areas_applicable',
    plotAreaMin: 'sidebar.search_plots.plot_area',
    plotAreaMax: 'sidebar.search_plots.plot_area',
    buildable: 'sidebar.search_plots.buildable',
    allowedFloorAreaMin: 'sidebar.search_plots.allowed_floor_area',
    allowedFloorAreaMax: 'sidebar.search_plots.allowed_floor_area',
    permittedBuildingFootprintMin: 'sidebar.search_plots.permitted_building_footprint',
    permittedBuildingFootprintMax: 'sidebar.search_plots.permitted_building_footprint',
    authorizedLevelMin: 'sidebar.search_plots.authorized_level',
    undevelopedPlots: 'sidebar.search_plots.search_undeveloped_plots',
    possibleElevations: 'sidebar.search_plots.possible_elevations',
    smallFootprints: 'sidebar.search_plots.small_footprints',
    currentBuildingFootprintMin: 'sidebar.search_plots.current_building_footprint',
    currentBuildingFootprintMax: 'sidebar.search_plots.current_building_footprint',
    buildingPurpose: 'sidebar.search_plots.building_purpose',
    constructionYearMin: 'sidebar.search_plots.construction_year',
    constructionYearMax: 'sidebar.search_plots.construction_year',
    eaveHeightMin: 'sidebar.search_plots.eave_height',
    eaveHeightMax: 'sidebar.search_plots.eave_height',
    numberOfLevelsMin: 'sidebar.search_plots.number_of_levels',
    numberOfLevelsMax: 'sidebar.search_plots.number_of_levels',
    buildingFloorAreaMin: 'sidebar.search_plots.building_floor_area',
    buildingFloorAreaMax: 'sidebar.search_plots.building_floor_area',
    roofingMaterial: 'sidebar.search_plots.roofing_material',
    wallMaterial: 'sidebar.search_plots.wall_material',
    coOwnerLots: 'sidebar.search_plots.coOwnerLots',
    excludeFloodProneAreas: 'sidebar.search_plots.exclude_flood_prone_areas',
    officeConstruction: 'sidebar.search_plots.office_construction',
    storeConstruction: 'sidebar.search_plots.store_construction',
    warehouseConstruction: 'sidebar.search_plots.warehouse_construction',
    removeStreets: 'sidebar.search_plots.remove_streets',
    roadLinearMin: 'sidebar.search_plots.road_linear',
    roadLinearMax: 'sidebar.search_plots.road_linear',
    reducedVATZone: 'sidebar.search_plots.reduced_vat_zone',
    numberOfOwners: 'sidebar.search_plots.number_of_owners',
    typeOfOwners: 'sidebar.search_plots.type_of_owners',
    titleDeeds: 'sidebar.search_plots.title_deeds',
    ownerMainActivity: 'sidebar.search_plots.owner_main_activity',
    ownerName: 'sidebar.search_plots.owner_name',
    ownerSiren: 'sidebar.search_plots.owner_siren',
    prefix: '',
    section: '',
    number: '',
    socialMix: 'sidebar.search_plots.social_mix_remove',
    socialMixMin: 'sidebar.search_plots.social_mix',
    socialMixMax: 'sidebar.search_plots.social_mix',
    farming: 'sidebar.search_plots.farming',
};
export const IOPlotsSearchData = iots.type({
    filterByPlot: iots.boolean,
    prefix: iots.union([iots.string, iots.null]),
    section: iots.union([iots.string, iots.null]),
    number: iots.union([iots.string, iots.null]),
    filterByZones: iots.boolean,
    zones: iots.array(iots.string),
    filterByZonesApplicable: iots.boolean,
    zonesApplicable: iots.array(iots.string),
    filterByPlotArea: iots.boolean,
    plotAreaMin: iots.union([iots.string, iots.null]),
    plotAreaMax: iots.union([iots.string, iots.null]),
    filterByBuildable: iots.boolean,
    buildable: iots.array(iots.string),
    filterByAllowedFloorArea: iots.boolean,
    allowedFloorAreaMin: iots.union([iots.string, iots.null]),
    allowedFloorAreaMax: iots.union([iots.string, iots.null]),
    filterByPermittedBuildingFootprint: iots.boolean,
    permittedBuildingFootprintMin: iots.union([iots.string, iots.null]),
    permittedBuildingFootprintMax: iots.union([iots.string, iots.null]),
    filterByAuthorizedLevel: iots.boolean,
    authorizedLevelMin: iots.union([iots.string, iots.null]),
    filterByUndevelopedPlots: iots.boolean,
    possibleElevations: iots.array(iots.string),
    filterByPossibleElevations: iots.boolean,
    filterBySmallFootprints: iots.boolean,
    filterByCurrentBuildingFootprint: iots.boolean,
    currentBuildingFootprintMin: iots.union([iots.string, iots.null]),
    currentBuildingFootprintMax: iots.union([iots.string, iots.null]),
    filterByBuildingPurpose: iots.boolean,
    buildingPurpose: iots.string,
    filterByConstructionYear: iots.boolean,
    constructionYearMin: iots.union([iots.string, iots.null]),
    constructionYearMax: iots.union([iots.string, iots.null]),
    filterByEaveHeight: iots.boolean,
    eaveHeightMin: iots.union([iots.string, iots.null]),
    eaveHeightMax: iots.union([iots.string, iots.null]),
    filterByNumberOfLevels: iots.boolean,
    numberOfLevelsMin: iots.union([iots.string, iots.null]),
    numberOfLevelsMax: iots.union([iots.string, iots.null]),
    filterByBuildingFloorArea: iots.boolean,
    buildingFloorAreaMin: iots.union([iots.string, iots.null]),
    buildingFloorAreaMax: iots.union([iots.string, iots.null]),
    filterByRoofingMaterial: iots.boolean,
    roofingMaterial: iots.string,
    filterByWallMaterial: iots.boolean,
    wallMaterial: iots.string,
    filterByCoOwnerLots: iots.boolean,
    coOwnerLots: iots.string,
    filterByExcludeFloodProneAreas: iots.boolean,
    filterByRoadLinear: iots.boolean,
    filterByOfficeConstruction: iots.boolean,
    officeConstruction: iots.array(iots.string),
    filterByStoreConstruction: iots.boolean,
    storeConstruction: iots.array(iots.string),
    warehouseConstruction: iots.array(iots.string),
    filterByRemoveStreets: iots.boolean,
    roadLinearMin: iots.union([iots.string, iots.null]),
    roadLinearMax: iots.union([iots.string, iots.null]),
    filterByReducedVATZone: iots.boolean,
    reducedVATZone: iots.string,
    numberOfOwners: iots.array(iots.string),
    typeOfOwners: iots.array(iots.string),
    filterByTitleDeeds: iots.boolean,
    titleDeeds: iots.string,
    filterByOwnerMainActivity: iots.boolean,
    ownerMainActivity: iots.string,
    filterByOwnerName: iots.boolean,
    ownerName: iots.array(iots.string),
    filterByOwnerSiren: iots.boolean,
    ownerSiren: iots.string,
    filterByExcludeSocialMix: iots.boolean,
    socialMixMin: iots.union([iots.string, iots.null]),
    socialMixMax: iots.union([iots.string, iots.null]),
    filterBySocialMix: iots.boolean,
    farming: iots.array(iots.string),
});
export const IOPlotsSearchSavedFilterDetail = iots.type({
    filterByPlotArea: iots.boolean,
    plotAreaMin: iots.union([iots.number, iots.null]),
    plotAreaMax: iots.union([iots.number, iots.null]),
    filterByAllowedFloorArea: iots.boolean,
    allowedFloorAreaMin: iots.union([iots.number, iots.null]),
    allowedFloorAreaMax: iots.union([iots.number, iots.null]),
    filterByPermittedBuildingFootprint: iots.boolean,
    permittedBuildingFootprintMin: iots.union([iots.number, iots.null]),
    permittedBuildingFootprintMax: iots.union([iots.number, iots.null]),
    filterByCurrentBuildingFootprint: iots.boolean,
    currentBuildingFootprintMin: iots.union([iots.number, iots.null]),
    currentBuildingFootprintMax: iots.union([iots.number, iots.null]),
    filterByAuthorizedLevel: iots.boolean,
    authorizedLevelMin: iots.union([iots.number, iots.null]),
    filterByRoadLinear: iots.boolean,
    roadLinearMin: iots.union([iots.number, iots.null]),
    roadLinearMax: iots.union([iots.number, iots.null]),
    filterByZones: iots.boolean,
    zones: iots.array(iots.string),
    zonesApplicable: iots.array(iots.string),
    filterByReducedVATZone: iots.boolean,
    reducedVATZone: iots.string,
    filterByExcludeFloodProneAreas: iots.boolean,
    filterByUndevelopedPlots: iots.boolean,
    filterByBuildable: iots.boolean,
    buildable: iots.array(iots.string),
    filterByOfficeConstruction: iots.boolean,
    officeConstruction: iots.array(iots.string),
    filterByStoreConstruction: iots.boolean,
    storeConstruction: iots.array(iots.string),
    warehouseConstruction: iots.array(iots.string),
    numberOfOwners: iots.array(iots.string),
    typeOfOwners: iots.array(iots.string),
    filterByOwnerName: iots.boolean,
    ownerName: iots.array(iots.string),
    filterByOwnerSiren: iots.boolean,
    ownerSiren: iots.union([iots.string, iots.null]),
    filterByTitleDeeds: iots.boolean,
    titleDeeds: iots.union([iots.string, iots.null]),
    filterByOwnerMainActivity: iots.boolean,
    ownerMainActivity: iots.union([iots.string, iots.null]),
    filterByRemoveStreets: iots.boolean,
    possibleElevations: iots.array(iots.string),
    filterBySmallFootprints: iots.boolean,
    filterByBuildingPurpose: iots.boolean,
    buildingPurpose: iots.string,
    filterByConstructionYear: iots.boolean,
    constructionYearMin: iots.union([iots.number, iots.null]),
    constructionYearMax: iots.union([iots.number, iots.null]),
    filterByEaveHeight: iots.boolean,
    eaveHeightMin: iots.union([iots.number, iots.null]),
    eaveHeightMax: iots.union([iots.number, iots.null]),
    filterByNumberOfLevels: iots.boolean,
    numberOfLevelsMin: iots.union([iots.number, iots.null]),
    numberOfLevelsMax: iots.union([iots.number, iots.null]),
    filterByBuildingFloorArea: iots.boolean,
    buildingFloorAreaMin: iots.union([iots.number, iots.null]),
    buildingFloorAreaMax: iots.union([iots.number, iots.null]),
    filterByRoofingMaterial: iots.boolean,
    roofingMaterial: iots.string,
    filterByWallMaterial: iots.boolean,
    wallMaterial: iots.string,
    filterByCoOwnerLots: iots.boolean,
    coOwnerLots: iots.string,
    socialMixMin: iots.union([iots.string, iots.null]),
    socialMixMax: iots.union([iots.string, iots.null]),
    filterByExcludeSocialMix: iots.boolean,
    farming: iots.array(iots.string),
});
export const IOPlotsSearchSavedFilter = iots.type({
    id: iots.string,
    name: iots.string,
    filters: IOPlotsSearchSavedFilterDetail,
});
const cleanValue = (value) => {
    if (!value || value === '') {
        return null;
    }
    return value;
};
const formatSeparator = (value) => {
    value = value.replace(/,/g, ';');
    return value.replace(/\s/g, '');
};
export const formatSearchData = (formData, hasRegulationAnalysis = false) => {
    if (!formData) {
        return null;
    }
    const prefix = cleanValue(formData.prefix);
    const section = cleanValue(formData.section);
    const number = cleanValue(formData.number);
    const filterByPlot = !!prefix && !!section && !!number;
    const zones = (formData.zones || []).map((zone) => zone.label);
    const filterByZones = zones.length > 0;
    const zonesApplicable = (formData.zonesApplicable || []).map((zone) => zone.label);
    const filterByZonesApplicable = zonesApplicable.length > 0;
    const plotAreaMin = cleanValue(formData.plotAreaMin);
    const plotAreaMax = cleanValue(formData.plotAreaMax);
    const filterByPlotArea = !!plotAreaMin || !!plotAreaMax;
    const buildable = (formData.buildable || []).map((buildable) => buildable.value);
    const filterByBuildable = buildable.length > 0;
    const allowedFloorAreaMin = cleanValue(formData.allowedFloorAreaMin);
    const allowedFloorAreaMax = cleanValue(formData.allowedFloorAreaMax);
    const filterByAllowedFloorArea = !!allowedFloorAreaMin || !!allowedFloorAreaMax;
    const permittedBuildingFootprintMin = cleanValue(formData.permittedBuildingFootprintMin);
    const permittedBuildingFootprintMax = cleanValue(formData.permittedBuildingFootprintMax);
    const filterByPermittedBuildingFootprint = !!permittedBuildingFootprintMin || !!permittedBuildingFootprintMax;
    const authorizedLevelMin = cleanValue(formData.authorizedLevelMin);
    const filterByAuthorizedLevel = !!authorizedLevelMin;
    const filterByUndevelopedPlots = formData.undevelopedPlots;
    const possibleElevations = (formData.possibleElevations || []).map((elevation) => elevation.value);
    const filterByPossibleElevations = possibleElevations.length > 0;
    const filterBySmallFootprints = formData.smallFootprints;
    const currentBuildingFootprintMin = cleanValue(formData.currentBuildingFootprintMin);
    const currentBuildingFootprintMax = cleanValue(formData.currentBuildingFootprintMax);
    const filterByCurrentBuildingFootprint = !!currentBuildingFootprintMin || !!currentBuildingFootprintMax;
    const buildingPurpose = cleanValue(formData.buildingPurpose) || 'none';
    const filterByBuildingPurpose = buildingPurpose !== 'none';
    const constructionYearMin = cleanValue(formData.constructionYearMin);
    const constructionYearMax = cleanValue(formData.constructionYearMax);
    const filterByConstructionYear = !!constructionYearMin || !!constructionYearMax;
    const eaveHeightMin = cleanValue(formData.eaveHeightMin);
    const eaveHeightMax = cleanValue(formData.eaveHeightMax);
    const filterByEaveHeight = !!eaveHeightMin || !!eaveHeightMax;
    const numberOfLevelsMin = cleanValue(formData.numberOfLevelsMin);
    const numberOfLevelsMax = cleanValue(formData.numberOfLevelsMax);
    const filterByNumberOfLevels = !!numberOfLevelsMin || !!numberOfLevelsMax;
    const buildingFloorAreaMin = cleanValue(formData.buildingFloorAreaMin);
    const buildingFloorAreaMax = cleanValue(formData.buildingFloorAreaMax);
    const filterByBuildingFloorArea = !!buildingFloorAreaMin || !!buildingFloorAreaMax;
    const roofingMaterial = cleanValue(formData.roofingMaterial) || 'none';
    const filterByRoofingMaterial = roofingMaterial !== 'none';
    const wallMaterial = cleanValue(formData.wallMaterial) || 'none';
    const filterByWallMaterial = wallMaterial !== 'none';
    const filterByExcludeSocialMix = formData.socialMix;
    const socialMixMin = cleanValue(formData.socialMixMin);
    const socialMixMax = cleanValue(formData.socialMixMax);
    const filterBySocialMix = !!socialMixMin || !!socialMixMax;
    const coOwnerLots = cleanValue(formData.coOwnerLots) || 'none';
    const filterByCoOwnerLots = coOwnerLots !== 'none';
    const filterByExcludeFloodProneAreas = formData.excludeFloodProneAreas;
    const officeConstruction = (formData.officeConstruction || []).map((officeConstruction) => officeConstruction.value);
    const filterByOfficeConstruction = officeConstruction.length > 0;
    const storeConstruction = (formData.storeConstruction || []).map((storeConstruction) => storeConstruction.value);
    const filterByStoreConstruction = storeConstruction.length > 0;
    const warehouseConstruction = (formData.warehouseConstruction || []).map((warehouseConstruction) => warehouseConstruction.value);
    const filterByRemoveStreets = formData.removeStreets;
    const roadLinearMin = cleanValue(formData.roadLinearMin);
    const roadLinearMax = cleanValue(formData.roadLinearMax);
    const filterByRoadLinear = !!roadLinearMin || !!roadLinearMax;
    const reducedVATZone = cleanValue(formData.reducedVATZone) || 'none';
    const filterByReducedVATZone = reducedVATZone !== 'none';
    const numberOfOwners = (formData.numberOfOwners || []).map((numberOfOwner) => numberOfOwner.value);
    const typeOfOwners = (formData.typeOfOwners || []).map((typeOfOwner) => typeOfOwner.value);
    const titleDeeds = cleanValue(formData.titleDeeds) || '0';
    const filterByTitleDeeds = !!titleDeeds && titleDeeds !== '0';
    const ownerMainActivity = formData.ownerMainActivity &&
        cleanValue(formatSeparator(formData.ownerMainActivity));
    const filterByOwnerMainActivity = !!ownerMainActivity;
    const ownerName = (formData.ownerName || []).map((owner) => owner.value + ';' + owner.label);
    const filterByOwnerName = ownerName.length > 0;
    const ownerSiren = formData.ownerSiren && cleanValue(formData.ownerSiren);
    const filterByOwnerSiren = !!ownerSiren;
    const farming = (formData.farming || []).map((farming) => farming.value);
    const searchData = {
        filterByPlot,
        prefix,
        section,
        number,
        filterByZones,
        filterByZonesApplicable,
        zones,
        zonesApplicable,
        filterByPlotArea,
        plotAreaMin,
        plotAreaMax,
        filterByBuildable,
        buildable,
        filterByAllowedFloorArea,
        allowedFloorAreaMin,
        allowedFloorAreaMax,
        filterByPermittedBuildingFootprint,
        permittedBuildingFootprintMin,
        permittedBuildingFootprintMax,
        filterByAuthorizedLevel,
        authorizedLevelMin,
        filterByUndevelopedPlots,
        possibleElevations,
        filterByPossibleElevations,
        filterBySmallFootprints,
        filterByCurrentBuildingFootprint,
        currentBuildingFootprintMin,
        currentBuildingFootprintMax,
        filterByBuildingPurpose,
        buildingPurpose,
        filterByConstructionYear,
        constructionYearMin,
        constructionYearMax,
        filterByEaveHeight,
        eaveHeightMin,
        eaveHeightMax,
        filterByNumberOfLevels,
        numberOfLevelsMin,
        numberOfLevelsMax,
        filterByBuildingFloorArea,
        buildingFloorAreaMin,
        buildingFloorAreaMax,
        filterByRoofingMaterial,
        roofingMaterial,
        filterByWallMaterial,
        wallMaterial,
        filterByRoadLinear,
        filterByCoOwnerLots,
        coOwnerLots,
        filterByExcludeFloodProneAreas,
        filterByOfficeConstruction,
        officeConstruction,
        filterByStoreConstruction,
        storeConstruction,
        warehouseConstruction,
        filterByRemoveStreets,
        roadLinearMin,
        roadLinearMax,
        filterByReducedVATZone,
        reducedVATZone,
        numberOfOwners,
        typeOfOwners,
        filterByTitleDeeds,
        titleDeeds,
        filterByOwnerMainActivity,
        ownerMainActivity,
        filterByOwnerName,
        ownerName,
        filterByOwnerSiren,
        ownerSiren,
        filterBySocialMix,
        socialMixMin,
        socialMixMax,
        filterByExcludeSocialMix,
        farming,
    };
    // If the town does not have a regulation analysis, we reset some filters that become irrelevant
    if (!hasRegulationAnalysis) {
        searchData.filterByPermittedBuildingFootprint = false;
        searchData.permittedBuildingFootprintMin = 0;
        searchData.permittedBuildingFootprintMax = 0;
        searchData.filterByRoadLinear = false;
        searchData.roadLinearMin = 0;
        searchData.roadLinearMax = 0;
        searchData.filterByAuthorizedLevel = false;
        searchData.authorizedLevelMin = 0;
        searchData.filterByAllowedFloorArea = false;
        searchData.allowedFloorAreaMin = 0;
        searchData.allowedFloorAreaMax = 0;
        searchData.filterByBuildable = false;
        searchData.buildable = [];
        searchData.filterByOfficeConstruction = false;
        searchData.officeConstruction = [];
        searchData.filterByStoreConstruction = false;
        searchData.storeConstruction = [];
        searchData.warehouseConstruction = [];
    }
    return searchData;
};
export const searchHasFilter = (searchData) => {
    if (!searchData) {
        return false;
    }
    return (searchData.filterByPlot ||
        searchData.filterByPlotArea ||
        searchData.filterByAllowedFloorArea ||
        searchData.filterByPermittedBuildingFootprint ||
        searchData.filterByAuthorizedLevel ||
        searchData.filterByCurrentBuildingFootprint ||
        searchData.filterByEaveHeight ||
        searchData.filterByBuildingFloorArea ||
        searchData.filterByRoadLinear ||
        searchData.filterByZones ||
        searchData.filterByZonesApplicable ||
        searchData.filterByBuildable ||
        searchData.filterByUndevelopedPlots ||
        searchData.filterByPossibleElevations ||
        searchData.filterBySmallFootprints ||
        searchData.filterByBuildingPurpose ||
        searchData.filterByRoofingMaterial ||
        searchData.filterByWallMaterial ||
        searchData.filterByCoOwnerLots ||
        searchData.filterByExcludeFloodProneAreas ||
        searchData.filterByOfficeConstruction ||
        searchData.filterByStoreConstruction ||
        searchData.warehouseConstruction.length > 0 ||
        !searchData.filterByRemoveStreets ||
        searchData.filterByRemoveStreets ||
        searchData.filterByReducedVATZone ||
        searchData.numberOfOwners.length > 0 ||
        searchData.typeOfOwners.length > 0 ||
        searchData.filterByTitleDeeds ||
        searchData.filterByOwnerMainActivity ||
        searchData.filterByOwnerName ||
        searchData.filterByOwnerSiren ||
        searchData.filterByConstructionYear ||
        searchData.filterByNumberOfLevels ||
        searchData.filterByExcludeSocialMix ||
        searchData.filterBySocialMix ||
        searchData.farming.length > 0);
};
export const plotsSearchFilterDetailToFormData = (filterDetail, t) => {
    return {
        zones: filterDetail.zones.map((zone) => ({ label: zone })),
        zonesApplicable: filterDetail.zonesApplicable.map((zone) => ({
            label: zone,
        })),
        plotAreaMin: filterDetail.plotAreaMin?.toString(),
        plotAreaMax: filterDetail.plotAreaMax?.toString(),
        buildable: filterDetail.buildable.map((buildable) => ({
            value: buildable,
            label: t(BUILDABLE_OPTIONS_KEY[buildable] ?? ''),
        })),
        allowedFloorAreaMin: filterDetail.allowedFloorAreaMin?.toString() || null,
        allowedFloorAreaMax: filterDetail.allowedFloorAreaMax?.toString() || null,
        permittedBuildingFootprintMin: filterDetail.permittedBuildingFootprintMin?.toString() || null,
        permittedBuildingFootprintMax: filterDetail.permittedBuildingFootprintMax?.toString() || null,
        authorizedLevelMin: filterDetail.authorizedLevelMin?.toString() || null,
        undevelopedPlots: filterDetail.filterByUndevelopedPlots,
        possibleElevations: filterDetail.possibleElevations.map((elevation) => ({
            value: elevation,
            label: t(ELEVATION_OPTIONS_KEY[elevation] ?? ''),
        })),
        smallFootprints: filterDetail.filterBySmallFootprints,
        currentBuildingFootprintMin: filterDetail.currentBuildingFootprintMin?.toString() || null,
        currentBuildingFootprintMax: filterDetail.currentBuildingFootprintMax?.toString() || null,
        buildingPurpose: filterDetail.buildingPurpose,
        constructionYearMin: filterDetail.constructionYearMin?.toString() || null,
        constructionYearMax: filterDetail.constructionYearMax?.toString() || null,
        eaveHeightMin: filterDetail.eaveHeightMin?.toString() || null,
        eaveHeightMax: filterDetail.eaveHeightMax?.toString() || null,
        numberOfLevelsMin: filterDetail.numberOfLevelsMin?.toString() || null,
        numberOfLevelsMax: filterDetail.numberOfLevelsMax?.toString() || null,
        buildingFloorAreaMin: filterDetail.buildingFloorAreaMin?.toString() || null,
        buildingFloorAreaMax: filterDetail.buildingFloorAreaMax?.toString() || null,
        roofingMaterial: filterDetail.roofingMaterial,
        wallMaterial: filterDetail.wallMaterial,
        coOwnerLots: filterDetail.coOwnerLots,
        excludeFloodProneAreas: filterDetail.filterByExcludeFloodProneAreas,
        officeConstruction: filterDetail.officeConstruction.map((officeConstruction) => ({
            value: officeConstruction,
            label: getSelectedOptionsLabel(WAREHOUSE_CONSTRUCTION_KEY, 'construction', officeConstruction, t),
        })),
        storeConstruction: filterDetail.storeConstruction.map((storeConstruction) => ({
            value: storeConstruction,
            label: getSelectedOptionsLabel(WAREHOUSE_CONSTRUCTION_KEY, 'construction', storeConstruction, t),
        })),
        warehouseConstruction: filterDetail.warehouseConstruction.map((warehouseConstruction) => ({
            value: warehouseConstruction,
            label: getSelectedOptionsLabel(WAREHOUSE_CONSTRUCTION_KEY, 'construction', warehouseConstruction, t),
        })),
        removeStreets: filterDetail.filterByRemoveStreets,
        roadLinearMin: filterDetail.roadLinearMin?.toString() || null,
        roadLinearMax: filterDetail.roadLinearMax?.toString() || null,
        reducedVATZone: filterDetail.reducedVATZone,
        numberOfOwners: filterDetail.numberOfOwners.map((numberOfOwner) => ({
            value: numberOfOwner,
            label: getSelectedOptionsLabel(NUMBER_OF_OWNERS_KEY, 'numberOfOwners', numberOfOwner, t),
        })),
        typeOfOwners: filterDetail.typeOfOwners.map((typeOfOwner) => ({
            value: typeOfOwner,
            label: getSelectedOptionsLabel(TYPE_OF_OWNERS_KEY, 'typeOfOwners', typeOfOwner, t),
        })),
        titleDeeds: filterDetail.titleDeeds,
        ownerMainActivity: filterDetail.ownerMainActivity,
        ownerName: filterDetail.ownerName.map((owner) => {
            const data = owner.split(';');
            return {
                value: data[0],
                label: data[1] ?? data[0],
            };
        }),
        ownerSiren: filterDetail.ownerSiren,
        socialMix: filterDetail.filterByExcludeSocialMix,
        socialMixMin: filterDetail.socialMixMin,
        socialMixMax: filterDetail.socialMixMax,
        farming: filterDetail.farming.map((farming) => ({
            value: farming,
            label: getSelectedOptionsLabel(FARMING_KEY, 'farming', farming, t),
        })),
    };
};
export const COOWNERS_LOTS_KEY = {
    none: 'no',
    '0': 'all',
    '4': 'more_1',
    '7': 'more_2',
    '10': 'more_3',
};
export const ROOFING_MATERIAL_KEY = {
    none: 'none',
    '5': 'slate',
    '10': 'concrete',
    '13': 'tiles',
    '15': 'zinc_aluminium',
    '6': 'others',
    '0': 'unknown',
};
export const WALL_MATERIAL_KEY = {
    none: 'none',
    '7': 'chipboard',
    '14': 'concrete',
    '19': 'wood',
    '23': 'bricks',
    '26': 'millstone',
    '28': 'stone',
    '8': 'others',
    '0': 'unknown',
};
export const STORE_CONSTRUCTION_KEY = {
    // none: 'none',
    '2': 'allowed',
    '3': 'allowed_conditionally',
    '1': 'forbidden',
};
export const WAREHOUSE_CONSTRUCTION_KEY = {
    // none: 'none',
    '2': 'allowed',
    '3': 'allowed_conditionally',
    '1': 'forbidden',
};
export const OFFICE_CONSTRUCTION_KEY = {
    // none: 'none',
    '2': 'allowed',
    '3': 'allowed_conditionally',
    '1': 'forbidden',
};
export const NUMBER_OF_OWNERS_KEY = {
    // none: 'none',
    A: 'singleOwner',
    B: 'multipleOwners',
    C: 'naturalPersons',
};
export const BUILDING_PURPOSE_KEY = {
    '#5': 'housing',
    '#1': 'offices',
    '#2': 'public_services',
    '#4': 'industry',
    '#3': 'agriculture',
    '#0': 'unknown',
    none: 'none',
};
export const REDUCED_VAT_ZONE_KEY = {
    none: 'none',
    '1': 'inside_reduced_vat_zone',
    '0': 'outside_reduced_vat_zone',
};
export const TITLE_DEEDS_KEY = {
    '0': 'none',
    '1-P': 'owner',
    '2-G': 'manager_or_agent',
    '3-S': 'co_ownership_trustee',
    '4-N': 'bare_owner',
    '5-E': 'very_long_term_lease_taker',
    '6-B': 'building_lease_lessor',
    '7-R': 'building_lease_taker',
    '8-U': 'beneficiary',
    '9-F': 'real_estate',
    '10-W': 'rehabilitation_lease_taker',
    '11-V': 'rehabilitation_lease_lessor',
};
export const FARMING_KEY = {
    '1': 'farming_1',
    '2': 'farming_2',
    '3': 'farming_3',
    '4': 'farming_4',
    '5': 'farming_5',
    '6': 'farming_6',
    '7': 'farming_7',
    '8': 'farming_8',
    '9': 'farming_9',
    '11': 'farming_11',
    '14': 'farming_14',
    '15': 'farming_15',
    '16': 'farming_16',
    '17': 'farming_17',
    '18': 'farming_18',
    '19': 'farming_19',
    '20': 'farming_20',
    '21': 'farming_21',
    '22': 'farming_22',
    '23': 'farming_23',
    '24': 'farming_24',
    '25': 'farming_25',
    '26': 'farming_26',
    '28': 'farming_28',
};
export const TYPE_OF_OWNERS_OPTIONS = [
    { label: 'company', value: '0' },
    { label: 'hlm', value: '5' },
    { label: 'publicEstablishment', value: '9' },
    { label: 'commune', value: '4' },
    { label: 'state', value: '1' },
    { label: 'department', value: '3' },
    { label: 'region', value: '2' },
    { label: 'sem', value: '6' },
    { label: 'coOwner', value: '7' },
    { label: 'associate', value: '8' },
];
export const TYPE_OF_OWNERS_KEY = () => {
    const object = {};
    TYPE_OF_OWNERS_OPTIONS.map((option) => {
        object[option.value] = option.label;
    });
    return object;
};
