import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { flushSync } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { HOME_URL } from 'app';
import axios from 'axios';
import { isRight } from 'fp-ts/lib/These';
import * as iots from 'io-ts';
import { createContext } from 'use-context-selector';
import { get, post } from 'api/client';
import useActivityWatcher from 'hooks/useActivityWatcher';
import useCurrentSite from 'hooks/useCurrentSite';
import usePersistentContext from 'hooks/usePersistentContext';
import { IOEmpty, IOUserSite } from 'types/api';
export const ROLES = {
    ROLE_USER: 'ROLE_USER',
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_MANAGER: 'ROLE_MANAGER',
};
export const CUSTOMER_NUMBER_KELFONCIER = 'CLT999999';
export const SITE_ID_GESTION = '137';
// This returns a union type of all the possible roles values
const IORole = iots.keyof(Object.fromEntries(Object.keys(ROLES).map((key) => [ROLES[key], null])));
const IOUserData = iots.type({
    email: iots.string,
    name: iots.string,
    nbTownPerMonth: iots.number,
    nbSearchPerMonth: iots.number,
    twoFactorTotpEnabled: iots.boolean,
    twoFactorEmailEnabled: iots.boolean,
    twoFactorSmsEnabled: iots.boolean,
    twoFactorRequired: iots.boolean,
    sites: iots.array(IOUserSite),
    roles: iots.array(IORole),
    isManaged: iots.boolean,
    customerNumber: iots.string,
    tokenCalendar: iots.string,
});
const IOAuthSuccess = iots.type({
    token: iots.string,
    data: IOUserData,
});
export const TWO_FACTOR_METHOD_TOTP = 'totp';
export const TWO_FACTOR_METHOD_EMAIL = 'email';
export const TWO_FACTOR_METHOD_SMS = 'sms';
const IOTwoFactorMethod = iots.union([
    iots.literal(TWO_FACTOR_METHOD_EMAIL),
    iots.literal(TWO_FACTOR_METHOD_SMS),
    iots.literal(TWO_FACTOR_METHOD_TOTP),
]);
export const IOAuthTwoFactorInProgress = iots.type({
    twoFactorComplete: iots.literal(false),
    login: iots.literal('success'),
    twoFactorMethod: IOTwoFactorMethod,
    twoFactorContact: iots.union([iots.null, iots.string]),
    twoFactorAvailableMethods: iots.array(IOTwoFactorMethod),
});
const IOAuthResponseData = iots.union([
    IOAuthSuccess,
    IOAuthTwoFactorInProgress,
]);
const IORefreshTokenResponse = iots.type({
    token: iots.string,
    data: IOUserData,
});
const apiLogin = (formData) => post(IOAuthResponseData, 'login', formData);
const apiSendTwoFactor = (code, rememberDevice) => post(IOAuthSuccess, 'login/2fa', {
    _auth_code: code,
    trusted_device: rememberDevice ?? false,
});
const apiChangeTwoFactorMethod = (method) => post(IOAuthTwoFactorInProgress, 'login/2fa/change-provider', {
    provider: method,
});
const apiResendTwoFactorCode = () => get(IOEmpty, 'login/2fa/resend');
const apiLogout = () => post(IOEmpty, 'logout', undefined, {}, false);
const apiRefreshToken = () => post(IORefreshTokenResponse, 'token/refresh', undefined, {}, false, {
    withCredentials: true,
});
export const AUTH_DATA_KEY = 'auth_data';
const KEEP_ALIVE_KEY = 'keep_alive';
const NO_REFRESH_URLS = ['/api/login', '/api/token/refresh', '/api/login/2fa'];
// We try to get the token from the local storage to make sure
// the first request will not fail because of a missing token
// Doing this in the context would cause it to be called multiple times
// Doing this in a useEffect would fail, because it would be called after the requests are sent
const authData = JSON.parse(localStorage.getItem(AUTH_DATA_KEY));
if (authData?.token) {
    axios.defaults.headers.common.Authorization = `Bearer ${authData.token}`;
    axios.defaults.withCredentials = true;
}
export const AuthContext = createContext(null);
export const AuthProvider = ({ children }) => {
    const [keepAlive, setKeepAlive] = usePersistentContext(KEEP_ALIVE_KEY);
    const [authData, setAuthData] = usePersistentContext(AUTH_DATA_KEY);
    const { currentSiteId, setCurrentSite } = useCurrentSite();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const token = authData?.token || null;
    const isRefreshingToken = useRef(false);
    const lastToken = useRef(null);
    const userData = authData?.data || null;
    const loginError = authData?.error || null;
    // Auto logout after 30 minutes without refreshing the token if keep alive is not checked
    const logoutTimerIdRef = useRef(null);
    const { pushInstruction } = useMatomo();
    const updateAuthData = (newAuthData, updateCurrentSite = false) => {
        let updatedSite = null;
        if (updateCurrentSite && 'data' in newAuthData) {
            updatedSite = newAuthData.data.sites.find((site) => site.legacyId === currentSiteId);
            if (!updatedSite) {
                updatedSite = newAuthData.data.sites[0];
            }
        }
        flushSync(() => {
            setAuthData(newAuthData);
            if (updatedSite) {
                setCurrentSite(updatedSite);
            }
        });
    };
    const updateUserData = async (updater) => {
        const newAuthData = {
            ...authData,
            data: { ...updater(userData) },
        };
        await updateAuthData(newAuthData);
    };
    useEffect(() => {
        if (authData) {
            pushInstruction('setUserId', userData?.email);
        }
    }, [authData]);
    const resetPersistedAuthData = async (error) => {
        lastToken.current = null;
        isRefreshingToken.current = false;
        await setKeepAlive(false);
        await updateAuthData({ error });
    };
    const resetAuthData = async (error) => {
        await resetPersistedAuthData(error);
        await setCurrentSite(null);
        await queryClient.removeQueries();
    };
    const refreshHandler = useMemo(() => async (error) => {
        const originalRequest = error.config;
        if (error?.response?.status !== 401 ||
            NO_REFRESH_URLS.includes(originalRequest.url) ||
            originalRequest.retried) {
            return Promise.reject(error);
        }
        // already refreshing the token, we just wait for a while and try to get the new token
        if (isRefreshingToken.current) {
            for (let i = 0; i < 3; i++) {
                await new Promise((resolve) => setTimeout(resolve, 2000));
                if (!isRefreshingToken.current) {
                    break;
                }
            }
            return retryRequest(originalRequest);
        }
        // refresh token
        try {
            isRefreshingToken.current = true;
            const refreshTokenResponse = await apiRefreshToken();
            await updateAuthData(refreshTokenResponse, true);
            lastToken.current = refreshTokenResponse.token;
            isRefreshingToken.current = false;
            return retryRequest(originalRequest);
        }
        catch (e) {
            isRefreshingToken.current = false;
            //eslint-disable-next-line
            console.error('Refresh token could not be retrieved, logging out');
            await logout(false);
        }
    }, [authData, currentSiteId]);
    const retryRequest = (originalRequest) => {
        originalRequest.retried = true;
        if (originalRequest.url.startsWith('/api')) {
            originalRequest.headers.Authorization = `Bearer ${lastToken.current}`;
        }
        return axios(originalRequest);
    };
    const handleLoginSuccess = async (newAuthData, redirect = HOME_URL) => {
        await updateAuthData(newAuthData);
        const sites = newAuthData.data.sites;
        let defaultSite = sites.length > 0 ? sites[0] : null;
        const currentSite = new URL(window.location.href);
        const hostname = currentSite.hostname === 'jolicode.kelfoncier.com' ||
            currentSite.hostname === 'kelfoncier.test'
            ? 'immo.kelfoncier.com'
            : currentSite.hostname;
        sites.forEach((site) => {
            if (site.url === hostname) {
                defaultSite = site;
            }
        });
        await setCurrentSite(defaultSite);
        navigate(redirect);
    };
    const login = async (formData, redirect) => {
        try {
            const newAuthData = await apiLogin(formData);
            await setKeepAlive(formData.keepAlive);
            if (isRight(IOAuthTwoFactorInProgress.decode(newAuthData))) {
                return newAuthData;
            }
            await handleLoginSuccess(newAuthData, redirect);
            return true;
        }
        catch (e) {
            await resetAuthData(e?.message);
            return false;
        }
    };
    const sendTwoFactorCode = async (code, rememberDevice, redirect) => {
        const newAuthData = await apiSendTwoFactor(code, rememberDevice);
        await handleLoginSuccess(newAuthData, redirect);
        return true;
    };
    const changeTwoFactorMethod = async (method) => {
        return await apiChangeTwoFactorMethod(method);
    };
    const resendTwoFactorCode = async () => {
        await apiResendTwoFactorCode();
    };
    const logout = async (remote = true, reload = true) => {
        try {
            if (remote) {
                await apiLogout();
            }
        }
        finally {
            navigate({ pathname: '', search: '' });
            await resetPersistedAuthData();
            if (reload) {
                window.location.reload();
            }
        }
    };
    const isAuthenticated = !!authData?.token && !!authData?.data;
    useEffect(() => {
        if (!!authData?.token && !authData?.data?.email) {
            Sentry.captureException(new Error('Connection error: No user data in authData. User data: ' +
                JSON.stringify(authData?.data)));
            logout();
        }
    }, [authData]);
    const clearLogoutTimer = () => {
        if (logoutTimerIdRef.current) {
            window.clearTimeout(logoutTimerIdRef.current);
            logoutTimerIdRef.current = null;
        }
    };
    const resetLogoutTimer = useCallback(() => {
        clearLogoutTimer();
        if (keepAlive || !isAuthenticated) {
            return;
        }
        logoutTimerIdRef.current = window.setTimeout(() => {
            logout();
        }, 60 * 60 * 1000); // 60 minutes/1 heure
    }, [keepAlive, isAuthenticated, logout]);
    // Everytime we log in or out we reset the logout timer
    useEffect(() => {
        resetLogoutTimer();
        // On cleanup we clear the timeout
        return () => {
            clearLogoutTimer();
        };
    }, [isAuthenticated]);
    // Everytime the user does something we reset the logout timer
    useActivityWatcher(resetLogoutTimer);
    return (<AuthContext.Provider value={{
            userData,
            loginError,
            isAuthenticated,
            login,
            sendTwoFactorCode,
            changeTwoFactorMethod,
            resendTwoFactorCode,
            logout,
            token,
            keepAlive,
            refreshHandler,
            updateUserData,
        }}>
      {children}
    </AuthContext.Provider>);
};
export default AuthContext;
