import * as React from 'react';
import { memo, useEffect, useMemo } from 'react';
import { Box, TabPanel, TabPanels, Tabs as ChakraTabs } from '@chakra-ui/react';
import UserPlotsNewsPanel from 'components/userPlotsNews/UserPlotsNewsPanel';
import { TABS_VIEW_CLOSED, TABS_VIEW_CLOSED_VERTICAL, } from 'context/LayoutContext';
import { CONTACT_TAB_TYPE, CRM_TABS, PLOT_TAB_TYPE, PROJECT_TAB_TYPE, } from 'context/TabsContext';
import { useLayout, useSetLayoutHalf, useTabsSize, useTabsView, useToggleLayout, } from 'hooks/contexts/useLayoutContext';
import { useStreetViewMode } from 'hooks/contexts/useMapContext';
import { useContacts, useProjects, useSelectedTab, useTabs, } from 'hooks/contexts/useTabsContext';
import { filtersKey } from 'utils/filters';
import ActivityPanel from './activities/ActivityPanel';
import CourierPanel from './couriers/CourierPanel';
import ContactPanel from './crmTabs/contact/ContactPanel';
import MyContactsPanel from './crmTabs/contact/MyContactsPanel';
import MyPlotsPanel from './crmTabs/MyPlotsPanel';
import MyProjectsPanel from './crmTabs/MyProjectsPanel';
import ProjectPanel from './crmTabs/project/ProjectPanel';
import TeamAccessRightsPanel from './crmTabs/TeamAccessRightsPanel';
import TeamPlotsPanel from './crmTabs/TeamPlotsPanel';
import TeamProjectsPanel from './crmTabs/TeamProjectsPanel';
import TeamStatisticsPanel from './crmTabs/teamStatistics/TeamStatisticsPanel';
import TeamStatisticsCRMPanel from './crmTabs/TeamStatisticsCRMPanel';
import UserPersonalizationPanel from './crmTabs/UserPersonalizationPanel';
import UserStatisticsCRMPanel from './crmTabs/UserStatisticsCRMPanel';
import UserStatisticsPanel from './crmTabs/UserStatisticsPanel';
import AgricultureRearingCompanyPanel from './filterTabs/AgricultureRearingCompanyPanel';
import BankruptCompaniesPanel from './filterTabs/BankruptCompaniesPanel';
import BankruptCompaniesPlotsPanel from './filterTabs/BankruptCompaniesPlotsPanel';
import BuildingPermitPanel from './filterTabs/BuildingPermitPanel';
import CNACPanel from './filterTabs/CNACPanel';
import CompanyOwnedPlotsPanel from './filterTabs/CompanyOwnedPlotsPanel';
import DeathPanel from './filterTabs/DeathPanel';
import SCIPanel from './filterTabs/SCIPanel';
import SearchResultsPanel from './filterTabs/SearchResultsPanel';
import LandPointsPanel from './landPoint/LandPointsPanel';
import PlotPanel from './plots/PlotPanel';
import { TabsNavigation } from './TabsNavigation';
const TAB_CONTENT = {
    [filtersKey.BUILDING_PERMIT_HOUSING]: BuildingPermitPanel,
    [filtersKey.BUILDING_PERMIT_OFFICES]: BuildingPermitPanel,
    [filtersKey.BUILDING_PERMIT_PLANNING_PERMIT]: BuildingPermitPanel,
    [filtersKey.SCI]: SCIPanel,
    [filtersKey.BANKRUPT_COMPANIES]: BankruptCompaniesPanel,
    [filtersKey.BANKRUPT_COMPANIES_PLOTS]: BankruptCompaniesPlotsPanel,
    [filtersKey.CNAC]: CNACPanel,
    [filtersKey.DEATH]: DeathPanel,
    [filtersKey.COMPANY_OWNED_PLOTS]: CompanyOwnedPlotsPanel,
    [filtersKey.PLOTS_SEARCH]: SearchResultsPanel,
    [filtersKey.AGRICULTURE_REARING_COMPANY]: AgricultureRearingCompanyPanel,
    [CRM_TABS.USER_PLOTS]: MyPlotsPanel,
    [CRM_TABS.USER_PROJECTS]: MyProjectsPanel,
    [CRM_TABS.CONTACT_LIST]: MyContactsPanel,
    [CRM_TABS.TEAM_PLOTS]: TeamPlotsPanel,
    [CRM_TABS.TEAM_PROJECTS]: TeamProjectsPanel,
    [CRM_TABS.TEAM_ACCESS_RIGHTS]: TeamAccessRightsPanel,
    [CRM_TABS.TEAM_STATISTICS]: TeamStatisticsPanel,
    [CRM_TABS.TEAM_STATISTICS_CRM]: TeamStatisticsCRMPanel,
    [CRM_TABS.MY_STATISTICS]: UserStatisticsPanel,
    [CRM_TABS.MY_STATISTICS_CRM]: UserStatisticsCRMPanel,
    [CRM_TABS.ACTIVITY]: ActivityPanel,
    [CRM_TABS.COURIER]: CourierPanel,
    [CRM_TABS.USER_PLOTS_NEWS]: UserPlotsNewsPanel,
    [CRM_TABS.LAND_POINT]: LandPointsPanel,
    [CRM_TABS.PERSONALIZATION]: UserPersonalizationPanel,
};
const TabPanelContent = ({ tab }) => {
    const projects = useProjects();
    const contacts = useContacts();
    if (tab.type === PLOT_TAB_TYPE) {
        return <PlotPanel />;
    }
    if (tab.type === PROJECT_TAB_TYPE) {
        const project = projects[tab.id];
        return (<ProjectPanel projectId={project.id} projectTownId={project.townId}/>);
    }
    if (tab.type === CONTACT_TAB_TYPE) {
        const contact = contacts[tab.id];
        return <ContactPanel tabContact={contact}/>;
    }
    if (tab.id === CRM_TABS.PERSONALIZATION) {
        return <UserPersonalizationPanel />;
    }
    const TabContent = TAB_CONTENT[tab.id];
    return (<Box height="100%" paddingTop={2}>
      <TabContent id={tab.id}/>
    </Box>);
};
const TabsContainer = () => {
    const tabs = useTabs();
    const selectedTab = useSelectedTab();
    const streetViewMode = useStreetViewMode();
    const tabsView = useTabsView();
    const { tabsWidth, tabsHeight } = useTabsSize();
    const layout = useLayout();
    const toggleLayout = useToggleLayout();
    const setLayoutHalf = useSetLayoutHalf();
    const nbTabs = tabs.length;
    const selectedIndex = useMemo(() => {
        if (!selectedTab) {
            return null;
        }
        return tabs.findIndex((tab) => tab.id === selectedTab);
    }, [tabs, selectedTab]);
    useEffect(() => {
        if ([TABS_VIEW_CLOSED, TABS_VIEW_CLOSED_VERTICAL]?.includes(tabsView)) {
            setLayoutHalf();
        }
    }, [nbTabs]);
    if (!tabs?.length) {
        return null;
    }
    return (<Box zIndex={20} className="no-print" display={streetViewMode ? 'none' : 'initial'} width={tabsWidth} position={layout === 'horizontal' ? 'relative' : 'initial'} cursor={tabsView === TABS_VIEW_CLOSED_VERTICAL ? 'pointer' : 'initial'}>
      <Box data-cy="tabs_container" onClick={() => {
            if (tabsView === TABS_VIEW_CLOSED_VERTICAL) {
                toggleLayout();
            }
        }}>
        <ChakraTabs index={selectedIndex} variant={layout === 'horizontal' ? 'enclosed' : 'line'}>
          <TabsNavigation />

          <TabPanels backgroundColor="colorMode.background900" height={tabsHeight} id="TabsPanels" overflowY="auto" overflowX="hidden">
            {tabs.map((tab) => (<TabPanel key={tab.id} padding={0} height="100%">
                <Box overflow={tabsView === TABS_VIEW_CLOSED_VERTICAL
                ? 'hidden'
                : 'initial'} minWidth="400px" height="100%">
                  <TabPanelContent tab={tab}/>
                </Box>
              </TabPanel>))}
          </TabPanels>
        </ChakraTabs>
      </Box>
    </Box>);
};
export default memo(TabsContainer);
