import * as React from 'react';
import { memo } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import snakeCase from 'lodash/snakeCase';
import { get } from 'api/client';
import SirenLink from 'components/links/SirenLink';
import Table from 'components/table/Table';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import BankruptCompaniesOwnedPlotsButton from './BankruptCompaniesOwnedPlotsButton';
export const getColumnHeader = (column) => (<Text align="center">
    <Translation>
      {(t) => t(`tabs.extra_tabs.sci.columns.${column}`)}
    </Translation>
  </Text>);
export const IOSCITableData = iots.type({
    department: iots.string,
    town: iots.string,
    companyName: iots.string,
    siren: iots.string,
    countOwnedPlots: iots.number,
});
const columns = [
    {
        id: 'columns',
        columns: [
            {
                accessorKey: 'department',
                header: () => getColumnHeader('department'),
                cell: (info) => info.getValue(),
                enableGlobalFilter: false,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'town',
                header: () => getColumnHeader('town'),
                cell: (info) => info.getValue(),
                enableGlobalFilter: false,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'companyName',
                header: () => getColumnHeader('company_name'),
                cell: (info) => info.getValue(),
                enableGlobalFilter: false,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'siren',
                header: () => getColumnHeader('siren'),
                cell: (info) => {
                    return (<SirenLink siren={info.getValue()} analyticDetail="Pappers SCI" showExternalLink/>);
                },
                enableGlobalFilter: false,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'countOwnedPlots',
                header: () => getColumnHeader('owned_plots'),
                cell: (info) => {
                    return (<BankruptCompaniesOwnedPlotsButton data={{
                            siren: info.row.original.siren,
                            countOwnedPlots: info.row.original.countOwnedPlots,
                        }}/>);
                },
                enableGlobalFilter: false,
                filterFn: 'inNumberRange',
            },
        ],
    },
];
const EXPORTED_FIELDS = ['department', 'town', 'companyName', 'siren'];
const formatExportHeader = (t) => (header) => t(`tabs.extra_tabs.sci.columns.${snakeCase(header)}`);
const formatExportValue = (value, field, data) => {
    if (field === 'siren') {
        return data.siren;
    }
    return value;
};
const fetchSCI = (department) => () => get(iots.array(IOSCITableData), `${department}/sci`);
const SCIPanel = () => {
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const department = selectedTown?.department;
    const { data, isInitialLoading: isLoading } = useQuery({
        queryKey: ['sci', department],
        queryFn: fetchSCI(department),
        enabled: !!department,
    });
    return (<Stack paddingX={4} height="100%">
      <Table variant="simple" id="sci" columns={columns} data={data || []} isLoading={isLoading} exportTitle={t('tabs.extra_tabs.sci.tab_title')} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue} initialPagination={{
            pageIndex: 0,
            pageSize: 20,
        }}/>
    </Stack>);
};
export default memo(SCIPanel);
